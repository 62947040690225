<template>
  <section v-if="content?.length" class="space-y-8">
    <SectionHeader
      :title="t('Past Issues of {name}', { name: data?.name })"
      :path="data?.url"
    />
    <LytGridFourUp :length="content.length">
      <template #item="{ idx }">
        <template v-for="card in [getCardAt(content, idx)]" :key="card?.uuid">
          <Card v-if="card" :="card" />
        </template>
      </template>
    </LytGridFourUp>
  </section>
</template>

<script setup lang="ts">
import { getCardAt } from '~/utils/get-card'
const props = defineProps<{
  mag: string
  limit?: number
  currentIssue?: string
}>()
const { t } = useI18n()

const { data, status } = useLazyFetch(`/api/data/${props.mag}/issues`, {
  params: {
    limit: props.limit || 5,
  },
})

const content = computed(() =>
  (unref(data)?.content || []).filter(
    ({ uuid }) => uuid !== props.currentIssue,
  ),
)
</script>

<i18n lang="json">
{
  "en": {
    "Past Issues of {name}": "Past Issues of {name}"
  },
  "es": {
    "Past Issues of {name}": "Ejemplares anteriores de {name}"
  }
}
</i18n>
